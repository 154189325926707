@import './_mixins'

html,
body
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
  margin: 0
  max-width: 100vw
  padding: 0
  scroll-behavior: smooth
  width: 100vw

a
  color: inherit
  text-decoration: underline
  transition: all 0.25s ease

  &:hover
    color: #868686

*
  box-sizing: border-box

.section
  margin: 80px 0 0
  text-align: left

  @include breakpoints('lg')
    margin-top: 160px

h1
  color: #000
  display: block
  font-size: 18px
  font-weight: 700
  justify-content: end
  line-height: 24px
  margin: 40px 0 0 auto
  max-width: min-content
  padding-right: 10px
  text-align: right
  text-transform: uppercase

  @include breakpoints('sm')
    font-size: 24px
    line-height: 32px

  &::first-line
    font-size: 36px
    font-weight: 700
    line-height: 30px

    @include breakpoints('sm')
      font-size: 58px
      line-height: 64px

    @include breakpoints('md')
      font-size: 88px
      line-height: 96px

    @include breakpoints('lg')
      font-size: 98px
      line-height: 104px

img
  height: auto
  max-width: 100%
